.inspectorContainer {
    display: flex;
    flex-direction: column;
    padding: 6px;
}

.newPropertyForm {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    font: bold 12px helvetica, sans-serif;
    color: #f5f5f5;
    padding: 12px 4px;

    &_input {
        padding: 5px 8px;
        width: 100%;
        background-color: #424242; 
    }

    &_button {
        background: #7f85f5;
        border-radius: 2px;
        padding: 2px 10px;
        
    }
}

.inspector {
    display: inline-block;
    font: bold 14px helvetica, sans-serif;
    background-color: #212121; 
    color: #f5f5f5; 
    cursor: default;

    table {
        border-collapse: separate;
        border-spacing: 2px;
    }

    td, th {
        padding: 2px;
    }

    input {
        background-color: #424242; 
        color: #f5f5f5; 
        font: bold 12px helvetica, sans-serif;
        border: 0px;
        padding: 2px;

        &:disabled {
            background-color: #bdbdbd; 
            color: #616161; 
        }
    }

    select {
        background-color: #424242;
    }
}
