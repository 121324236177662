@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

body {
    background-color: #212121;
    width: 100%;
    height: 100%;
}

.hidden {
    display: none;
}

.visible {
    display: block;
}

.visible_flex {
    display: flex;
}

.diagram-component {
    width: 100%;
    height: 92vh;
    background-color: rgb(41, 41, 41);
    /* flex-basis: 80%; */
    margin-left: 0.5rem;
}



::-webkit-scrollbar {
    width: 1em;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

::-webkit-scrollbar-thumb {
    background-color: rgb(66, 66, 66);;
    outline: 1px solid rgb(66, 66, 66);;
}

.webchat__basic-transcript__scrollable::-webkit-scrollbar-thumb {
    background-color: rgb(127, 133, 245);
    outline: 1px solid rgb(127, 133, 245);
}

.webchat__basic-transcript__scrollable::-webkit-scrollbar {
    width: 2px
}

.loader {
    border-top-color: rgb(127, 133, 245);
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.accessDenied {
    color: #FFFFFF;
    padding: 32px;
}