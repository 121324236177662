.container {
    border: 2px solid #dedede;
    background-color: #f1f1f1;
    border-radius: 5px;
    padding: 4px;
    display: flex;
    flex-direction: column;
    margin: 0 0 4px 24px;
    width: 80%;
  }
  
  /* Darker chat container */
  .darker {
    border-color: #ccc;
    background-color: #ddd;
  }
  
  /* Style time text */
  .time-left {
    float: left;
    color: #999;
    font-size: 12px
  }
  
  .message {
    font-size: 12px
  }